// Polyfill for Jquery's getScript function
const loadScript = (source, beforeEl, _async = true, _defer = false, attrs = {}) =>
  new Promise((resolve, reject) => {
    let script = document.createElement('script')
    const prior = beforeEl || document.getElementsByTagName('script')[0]

    script.async = _async
    script.defer = _defer
    Object.keys(attrs).forEach(key => {
      const value = attrs[key]
      script.setAttribute(key, value)
    })

    function onloadHandler(_, isAbort) {
      if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
        script.onload = null
        script.onreadystatechange = null
        script = undefined

        if (isAbort) {
          // Skip execution frame to ensure script is loaded before calling back
          setTimeout(() => {
            reject()
          }, 0)
        } else {
          setTimeout(() => {
            resolve()
          }, 0)
        }
      }
    }

    script.onload = onloadHandler
    script.onreadystatechange = onloadHandler

    script.src = source
    prior.parentNode.insertBefore(script, prior)
  })

module.exports = {
  loadScript
}
