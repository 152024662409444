const getBrowser = ua => {
  const isEdge = /Edge\/\d\d/.test(ua)
  const likeChrome = /Chrome\/\d\d/.test(ua)
  switch (true) {
    case isEdge:
      return 'edge'
    case /Trident\/\d/.test(ua):
      return 'internet explorer'
    case /Firefox\/\d\d/.test(ua):
      return 'firefox'
    case /Safari\/\d\d\d/.test(ua) && !likeChrome:
      return 'safari'
    case likeChrome && !isEdge:
      return 'chrome'
    default:
      return ''
  }
}

const permission = {
  DEFAULT: 'default',
  GRANTED: 'granted',
  DENIED: 'denied'
}

const requestPermission = (id, cb) => {
  // Add the ability to add the shown class to an element while user is clicking permissions
  if (id) {
    const element = window.document.getElementById(id)
    if (element) {
      element.classList.add('shown')
    }
  }
  // Have to use deprecated callback as safari does not support promises with requestPermission
  if (window.Notification && window.Notification.requestPermission) {
    window.Notification.requestPermission(result => {
      if (result === 'denied' || result === 'default') {
        return cb(false)
      }
      return cb(true)
    })
  }
}

const getPermissionLevel = () => {
  /* Safari 6+, Chrome 23+, Edge */
  if (window.Notification && window.Notification.permissionLevel) {
    return window.Notification.permissionLevel
  }
  /* Firefox 23+ */
  if (window.Notification && window.Notification.permission) {
    return window.Notification.permission
  }
  /* Firefox Mobile */
  if (window.navigator.mozNotification) {
    return permission.GRANTED
  }
  return null
}

module.exports = {
  getBrowser,
  requestPermission,
  getPermissionLevel
}
