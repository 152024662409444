const getCookie = name => {
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`)
  return v ? v[2] : null
}

const setCookie = (name, value, days) => {
  const d = new Date()
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days)

  document.cookie = `${name}=${value};path=/;expires=${d.toUTCString()}`
}

const deleteCookie = (name, path) => {
  document.cookie = `${name}=; Path=/${path || ''}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

module.exports = {
  getCookie,
  setCookie,
  deleteCookie
}
