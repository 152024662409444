/**
 * getUrlParams
 * Get the url parameters from the page url
 * and add fallback for browsers that don't
 * support URLSearchParams
 */
const getUrlParams = () => {
  let params
  if (URLSearchParams) {
    params = new URLSearchParams(window.location.search)
  } else {
    params = new Map()
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (_, key, value) => {
      params.set(key, value)
    })
  }

  return params
}

module.exports = { getUrlParams }
