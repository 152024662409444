const _inView = (rect, viewport, { offsetX = 0, offsetY = 0 } = {}) =>
  // Intersection
  rect.right + offsetX >= viewport.left &&
  rect.bottom + offsetY >= viewport.top &&
  rect.left - offsetX <= viewport.right &&
  rect.top - offsetY <= viewport.bottom
const _isVisible = elem => !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length)

const elementInView = (
  elem,
  { containerClass, offsetX = 0, offsetY = 0 } = {},
  viewport = {
    top: 0,
    left: 0,
    bottom: window.innerHeight || document.documentElement.clientHeight,
    right: window.innerWidth || document.documentElement.clientWidth
  }
) => {
  const isVisible = _isVisible(elem)

  if (!isVisible) {
    return false
  }
  if (containerClass && Element.prototype.closest) {
    // Is element inside a container?
    const elementContainer = elem.closest(containerClass)
    if (elementContainer) {
      const containerRect = elementContainer.getBoundingClientRect()
      // Is container in view?
      if (_inView(containerRect, viewport)) {
        const bottom = containerRect.bottom + offsetY
        const left = containerRect.left - offsetX
        const right = containerRect.right + offsetX
        const top = containerRect.top - offsetY

        const containerRectWithOffset = {
          top: top > viewport.top ? top : viewport.top,
          right: right < viewport.right ? right : viewport.right,
          bottom: bottom < viewport.bottom ? bottom : viewport.bottom,
          left: left > viewport.left ? left : viewport.left
        }
        const rect = elem.getBoundingClientRect()

        // Is element in view of container?
        return _inView(rect, containerRectWithOffset)
      }
      return false
    }
  }
  const rect = elem.getBoundingClientRect()
  return _inView(rect, viewport, {
    offsetX,
    offsetY
  })
}

const isInViewport = elem => {
  const bounding = elem.getBoundingClientRect()
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

// If the user opens a new tab this will return true
const tabVisibilityChange = () => {
  let hidden
  let visibilityChange
  if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden'
    visibilityChange = 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden'
    visibilityChange = 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden'
    visibilityChange = 'webkitvisibilitychange'
  }

  return { hidden, visibilityChange }
}

const docIsReady = callback => {
  if (document.readyState === 'complete' || document.readyState === 'interactive') {
    // call on next available tick
    setTimeout(callback, 1)
  } else {
    document.addEventListener('DOMContentLoaded', callback)
  }
}

module.exports = {
  docIsReady,
  isInViewport,
  tabVisibilityChange,
  elementInView
}
